import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Project from './Project';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import About from './About';
import './NavBar.css';

function App() {
  const [repos, setRepos] = useState([]);
  const [readmes, setReadmes] = useState([]);

  useEffect(() => {
    const getRepos = async () => {
      const response = await axios.get('https://api.github.com/users/A3Gold/repos', {
        headers: {
          Authorization: `token ${process.env.REACT_APP_GITHUB_TOKEN}`
        }
      });
      setRepos(response.data);
    }
    getRepos();
  }, []);

  useEffect(() => {
    document.title = "Adam G."
  }, []);

  useEffect(() => {
    const getReadmes = async () => {
      const tempReadmes = await Promise.all(repos.map(async (repo) => {
        try {
          const response = await axios.get(`https://api.github.com/repos/A3Gold/${repo.name}/readme`, {
            headers: {
              Authorization: `token ${process.env.REACT_APP_GITHUB_TOKEN}`,
              Accept: 'application/vnd.github.VERSION.raw'
            }
          });
          return response.data;
        } catch (error) {
          console.error(error);
          return 'No README available for this project.';
        }
      }));
      setReadmes(tempReadmes);
    }
    if (repos.length > 0) {
      getReadmes();
    }
  }, [repos]);

  return (
    <Router>
      <div>
        <nav className="navbar">
          <ul>
            <li><Link to="/">Projects</Link></li>
            <li><Link to="/about">About</Link></li>
          </ul>
        </nav>

        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/" element={
            <>
              <div className="App">
              <h1 className="title">Personal Projects</h1>
              {repos.map((repo, index) => (
              <Project key={repo.name} repo={repo} readme={readmes[index]} />
              ))}
    </div>
            </>
          }/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
