import React from 'react';
import './App.css';

function About() {
  return (
    <div>
      <h2 className="about-heading">About Me</h2>
      <p className="about-paragraph">Hi and thanks for visiting my website! I am a Computer Engineering student at the University of Waterloo. I hope you enjoy checking out some of the projects I am passionate about. Please feel free to connect with me below.</p>
      <div className="social-links">
        <a href="https://linkedin.com/in/adam-goldman01" target="_blank" rel="noopener noreferrer">
          <img src="linkedin-icon.png" alt="LinkedIn" className="social-icon" />
        </a>
        <a href="https://github.com/A3Gold" target="_blank" rel="noopener noreferrer">
          <img src="github-icon.png" alt="GitHub" className="social-icon" />
        </a>
        <a href="mailto:a3goldman@uwaterloo.ca">
          <img src="email-icon.png" alt="Email" className="social-icon" />
        </a>
      </div>
    </div>
  );
}

export default About;
