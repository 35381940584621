import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import './App.css';

function Project({ repo, readme }) {
  const [isOpen, setIsOpen] = useState(false);

  const components = {
    a: ({ node, ...props }) => {
      if (props.href.startsWith('https://y')) {
        return <a className="repo-link" target="_blank" {...props} children={"YouTube Video"} />;
      } else {
        return <a {...props} />;
      }
    },
  };

  return (
    <div className="project">
      <h2
        className="project-title"
        onClick={() => setIsOpen(!isOpen)}
      >
        {repo.name}
      </h2>

      {isOpen && (
        <>
          <ReactMarkdown
            className="project-readme"
            remarkPlugins={[gfm]}
            components={components}
          >
            {readme
              .split('\n')
              .filter(line => !line.includes('Video'))
              .join('\n')
            }
          </ReactMarkdown>
          <a href={repo.html_url} className="repo-link" target="_blank">
            Repository
          </a>
        </>
      )}
    </div>
  );
}

export default Project;
